<template>
  <div>
    <CRow>
      <CCol md="8">
        <CCard>
          <CCardHeader>
            <strong>Profil</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="6">
                <CInput
                  type="text"
                  label="Ad"
                  placeholder="Adınızı girin"
                  v-model="updateProfile.form.name"
                  :class="{ shake: updateProfile.fields.name.options.shake }"
                  :invalid-feedback="updateProfile.fields.name.valid.text"
                  :is-valid="updateProfile.fields.name.valid.value"
                  @input="validInput($event, updateProfile.fields.name)"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  type="text"
                  label="Soyad"
                  placeholder="Soyadınızı girin"
                  v-model="updateProfile.form.lastname"
                  :class="{
                    shake: updateProfile.fields.lastname.options.shake,
                  }"
                  :invalid-feedback="updateProfile.fields.lastname.valid.text"
                  :is-valid="updateProfile.fields.lastname.valid.value"
                  @input="validInput($event, updateProfile.fields.lastname)"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  type="text"
                  label="Kullanıcı Adı"
                  placeholder="Kullanıcı adınızı girin"
                  v-model="updateProfile.form.username"
                  :class="{
                    shake: updateProfile.fields.username.options.shake,
                  }"
                  :invalid-feedback="updateProfile.fields.username.valid.text"
                  :is-valid="updateProfile.fields.username.valid.value"
                  @input="validInput($event, updateProfile.fields.username)"
                />
              </CCol>
              <CCol md="6">
                <p class="m-0">Yetki</p>
                <p class="m-0 mt-2">{{ updateProfile.form.auth }}</p>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton
              color="success"
              :disabled="updateProfile.btnDisable"
              @click="methodUpdateProfile()"
              >Güncelle
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol md="4">
        <CCard>
          <CCardHeader>
            <strong>Şifre Değiştir</strong>
          </CCardHeader>
          <CCardBody>
            <CInput
              type="password"
              label="Eski Şifre"
              placeholder="Eski şifrenizi girin"
              v-model="updatePassword.form.oldPassword"
              :class="{
                shake: updatePassword.fields.oldPassword.options.shake,
              }"
              :invalid-feedback="updatePassword.fields.oldPassword.valid.text"
              :is-valid="updatePassword.fields.oldPassword.valid.value"
              @input="validInput($event, updatePassword.fields.oldPassword)"
            />
            <CInput
              type="password"
              label="Yeni Şifre"
              placeholder="Yeni şifrenizi girin"
              v-model="updatePassword.form.newPassword"
              :class="{
                shake: updatePassword.fields.newPassword.options.shake,
              }"
              :invalid-feedback="updatePassword.fields.newPassword.valid.text"
              :is-valid="updatePassword.fields.newPassword.valid.value"
              @input="validInput($event, updatePassword.fields.newPassword)"
            />
            <CInput
              type="password"
              label="Yeni Şifre (Tekrar)"
              placeholder="Yeni şifrenizi tekrar girin"
              v-model="updatePassword.form.newPasswordAgain"
              :class="{
                shake: updatePassword.fields.newPasswordAgain.options.shake,
              }"
              :invalid-feedback="
                updatePassword.fields.newPasswordAgain.valid.text
              "
              :is-valid="updatePassword.fields.newPasswordAgain.valid.value"
              @input="
                validInput($event, updatePassword.fields.newPasswordAgain)
              "
            />
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton
              color="success"
              :disabled="updatePassword.btnDisable"
              @click="methodUpdatePassword()"
              >Değiştir
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import Vue from "vue";
import axios from "axios";
import store from "../store";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "Profile",
  created() {
    this.getProfile();
  },
  data() {
    return {
      // PROFİL
      updateProfile: {
        btnDisable: false,
        fields: {
          name: {
            options: {
              min: 2,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          lastname: {
            options: {
              min: 2,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          username: {
            options: {
              min: 3,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
        },
        form: {
          name: "",
          lastname: "",
          username: "",
          auth: "",
        },
      },
      // ŞİFRE DEĞİŞİKLİĞİ
      updatePassword: {
        btnDisable: false,
        fields: {
          oldPassword: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          newPassword: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          newPasswordAgain: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
        },
        form: {
          oldPassword: "",
          newPassword: "",
          newPasswordAgain: "",
        },
      },
    };
  },
  watch: {
    "$i18n.locale": function () {
      if (this.updateProfile.fields.name.valid.value !== null)
        this.validInput(
          this.updateProfile.form.name,
          this.updateProfile.fields.name
        );
      if (this.updateProfile.fields.lastname.valid.value !== null)
        this.validInput(
          this.updateProfile.form.lastname,
          this.updateProfile.fields.lastname
        );
      if (this.updateProfile.fields.usernamename.valid.value !== null)
        this.validInput(
          this.updateProfile.form.username,
          this.updateProfile.fields.username
        );

      if (this.updatePasswordForm.fields.oldPassword.valid.value !== null)
        this.validInput(
          this.updatePasswordForm.oldPassword,
          this.updatePassword.fields.oldPassword
        );
      if (this.updatePasswordForm.fields.newPassword.valid.value !== null)
        this.validInput(
          this.updatePasswordForm.newPassword,
          this.updatePassword.fields.newPassword
        );
      if (this.updatePasswordForm.fields.newPasswordAgain.valid.value !== null)
        this.validInput(
          this.updatePasswordForm.newPasswordAgain,
          this.updatePassword.fields.newPasswordAgain
        );
    },
  },
  methods: {
    cleanProfileForm() {
      this.updateProfile.fields.name.valid.value = null;
      this.updateProfile.fields.name.valid.text = "";
      this.updateProfile.fields.lastname.valid.value = null;
      this.updateProfile.fields.lastname.valid.text = "";
      this.updateProfile.fields.username.valid.value = null;
      this.updateProfile.fields.username.valid.text = "";

      this.updateProfile.form.name = "";
      this.updateProfile.form.lastname = "";
      this.updateProfile.form.username = "";
      this.updateProfile.form.auth = "";
    },
    cleanUpdatePasswordForm() {
      this.updatePassword.fields.oldPassword.valid.value = null;
      this.updatePassword.fields.oldPassword.valid.text = "";
      this.updatePassword.fields.newPassword.valid.value = null;
      this.updatePassword.fields.newPassword.valid.text = "";
      this.updatePassword.fields.newPasswordAgain.valid.value = null;
      this.updatePassword.fields.newPasswordAgain.valid.text = "";

      this.updatePassword.form.oldPassword = "";
      this.updatePassword.form.newPassword = "";
      this.updatePassword.form.newPasswordAgain = "";
    },
    validInput(val, field) {
      if (val) {
        switch (field) {
          case this.updateProfile.fields.name:
            if (
              val.length < field.options.min ||
              val.length > field.options.max
            ) {
              field.valid.text =
                "Bu alana en az " +
                field.options.min +
                ", en fazla " +
                field.options.max +
                " karakter girilebilir!";
              field.valid.value = false;
            } else if (!val.match(/^[a-zA-ZığüşöçĞÜŞİÖÇ ]+$/g)) {
              field.valid.text =
                "Bu alana Türkçe karakter dahil küçük harf ve büyük harf girilebilir!";
              field.valid.value = false;
            } else {
              field.valid.text = "";
              field.valid.value = true;
            }
            break;
          case this.updateProfile.fields.lastname:
            if (
              val.length < field.options.min ||
              val.length > field.options.max
            ) {
              field.valid.text =
                "Bu alana en az " +
                field.options.min +
                ", en fazla " +
                field.options.max +
                " karakter girilebilir!";
              field.valid.value = false;
            } else if (!val.match(/^[a-zA-ZığüşöçĞÜŞİÖÇ ]+$/g)) {
              field.valid.text =
                "Bu alana Türkçe karakter dahil küçük harf ve büyük harf girilebilir!";
              field.valid.value = false;
            } else {
              field.valid.text = "";
              field.valid.value = true;
            }
            break;
          case this.updateProfile.fields.username:
            if (
              val.length < field.options.min ||
              val.length > field.options.max
            ) {
              field.valid.text =
                "Bu alana en az " +
                field.options.min +
                ", en fazla " +
                field.options.max +
                " karakter girilebilir!";
              field.valid.value = false;
            } else if (!val.match(/^[a-zA-Z0-9_.]+$/g)) {
              field.valid.text =
                "Bu alana boşluk bırakmadan küçük harf, büyük harf, sayı ve . (nokta) _ (alt çizgi) girilebilir!";
              field.valid.value = false;
            } else {
              field.valid.text = "";
              field.valid.value = true;
            }
            break;
          case this.updatePassword.fields.oldPassword:
            if (
              val.length < field.options.min ||
              val.length > field.options.max
            ) {
              field.valid.text =
                "Bu alana en az " +
                field.options.min +
                ", en fazla " +
                field.options.max +
                " karakter girilebilir!";
              field.valid.value = false;
            } else if (val.match(/\s/g)) {
              field.valid.text = "Bu alana boşluk girilemez!";
              field.valid.value = false;
            } else {
              field.valid.text = "";
              field.valid.value = true;
            }
            break;
          case this.updatePassword.fields.newPassword:
            if (
              val.length < field.options.min ||
              val.length > field.options.max
            ) {
              field.valid.text =
                "Bu alana en az " +
                field.options.min +
                ", en fazla " +
                field.options.max +
                " karakter girilebilir!";
              field.valid.value = false;
            } else if (val.match(/\s/g)) {
              field.valid.text = "Bu alana boşluk girilemez!";
              field.valid.value = false;
            } else {
              field.valid.text = "";
              field.valid.value = true;
            }
            break;
          case this.updatePassword.fields.newPasswordAgain:
            if (
              val.length < field.options.min ||
              val.length > field.options.max
            ) {
              field.valid.text =
                "Bu alana en az " +
                field.options.min +
                ", en fazla " +
                field.options.max +
                " karakter girilebilir!";
              field.valid.value = false;
            } else if (val.match(/\s/g)) {
              field.valid.text = "Bu alana boşluk girilemez!";
              field.valid.value = false;
            } else if (val !== this.updatePassword.form.newPassword) {
              field.valid.text = "Bu alan yeni şifre ile aynı olmalıdır!";
              field.valid.value = false;
            } else {
              field.valid.text = "";
              field.valid.value = true;
            }
            break;
        }
      } else {
        field.valid.text = "Bu alan boş bırakılamaz!";
        field.valid.value = false;
      }
    },
    getProfile() {
      this.$Progress.start();
      this.updateProfile.btnDisable = true;
      this.updatePassword.btnDisable = true;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/profile/")
        .then((response) => {
          this.$Progress.finish();
          this.updateProfile.btnDisable = false;
          this.updatePassword.btnDisable = false;

          if (response.data.result === "success") {
            this.updateProfile.form.name = response.data.profile.name;
            this.updateProfile.form.lastname = response.data.profile.lastname;
            this.updateProfile.form.username = response.data.profile.username;
            this.updateProfile.form.auth = response.data.profile.auth;
          } else {
            switch (response.data.errCode) {
              case "PROFILE_INFO_NOT_FOUND":
                Vue.swal({
                  icon: "error",
                  title: "Profil",
                  html: "Profil bilgisi bulunamadı!",
                  confirmButtonText: "Tamam",
                });
                break;
            }
          }
        });
    },
    methodUpdateProfile() {
      this.validInput(
        this.updateProfile.form.name,
        this.updateProfile.fields.name
      );
      this.validInput(
        this.updateProfile.form.lastname,
        this.updateProfile.fields.lastname
      );
      this.validInput(
        this.updateProfile.form.username,
        this.updateProfile.fields.username
      );
      this.validInput(
        this.updateProfile.form.password,
        this.updateProfile.fields.password
      );

      // Form içinde eğer name alanında hata var ise shake yapıyoruz.
      if (!this.updateProfile.fields.name.valid.value) {
        this.updateProfile.fields.name.options.shake = true;

        setTimeout(() => {
          this.updateProfile.fields.name.options.shake = false;
        }, 1000);
      }

      // Form içinde eğer lastname alanında hata var ise shake yapıyoruz.
      if (!this.updateProfile.fields.lastname.valid.value) {
        this.updateProfile.fields.lastname.options.shake = true;

        setTimeout(() => {
          this.updateProfile.fields.lastname.options.shake = false;
        }, 1000);
      }

      // Form içinde eğer username alanında hata var ise shake yapıyoruz.
      if (!this.updateProfile.fields.username.valid.value) {
        this.updateProfile.fields.username.options.shake = true;

        setTimeout(() => {
          this.updateProfile.fields.username.options.shake = false;
        }, 1000);
      }

      if (
        !this.updateProfile.fields.name.valid.value ||
        !this.updateProfile.fields.lastname.valid.value ||
        !this.updateProfile.fields.username.valid.value
      )
        return;

      this.$Progress.start();
      this.updateProfile.btnDisable = true;

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/profile/",
          {
            name: this.updateProfile.form.name,
            lastname: this.updateProfile.form.lastname,
            username: this.updateProfile.form.username,
          },
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish();
          this.updateProfile.btnDisable = false;

          if (response.data.result === "success") {
            Vue.swal({
              icon: "success",
              title: "Profil bilgileriniz başarıyla güncellendi.",
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Vue.swal.stopTimer);
                toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
              },
            });
          } else {
            switch (response.data.errCode) {
              case "USERNAME_IS_REGISTERED":
                Vue.swal({
                  icon: "error",
                  title: "Profil",
                  html:
                    "<strong>" +
                    response.data.username +
                    "</strong> kullanıcı adı zaten kayıtlı!",
                  confirmButtonText: "Tamam",
                });
                break;
              case "UPDATE_PROFILE_INFO_FAILED":
                Vue.swal({
                  icon: "error",
                  title: "Profil",
                  html: "Profil bilgileri güncellenemedi!",
                  confirmButtonText: "Tamam",
                });
                break;
            }
          }
        });
    },
    methodUpdatePassword() {
      this.validInput(
        this.updatePassword.form.oldPassword,
        this.updatePassword.fields.oldPassword
      );
      this.validInput(
        this.updatePassword.form.newPassword,
        this.updatePassword.fields.newPassword
      );
      this.validInput(
        this.updatePassword.form.newPasswordAgain,
        this.updatePassword.fields.newPasswordAgain
      );

      // Form içinde eğer oldPassword alanında hata var ise shake yapıyoruz.
      if (!this.updatePassword.fields.oldPassword.valid.value) {
        this.updatePassword.fields.oldPassword.options.shake = true;

        setTimeout(() => {
          this.updatePassword.fields.oldPassword.options.shake = false;
        }, 1000);
      }

      // Form içinde eğer newPassword alanında hata var ise shake yapıyoruz.
      if (!this.updatePassword.fields.newPassword.valid.value) {
        this.updatePassword.fields.newPassword.options.shake = true;

        setTimeout(() => {
          this.updatePassword.fields.newPassword.options.shake = false;
        }, 1000);
      }

      // Form içinde eğer newPasswordAgain alanında hata var ise shake yapıyoruz.
      if (!this.updatePassword.fields.newPasswordAgain.valid.value) {
        this.updatePassword.fields.newPasswordAgain.options.shake = true;

        setTimeout(() => {
          this.updatePassword.fields.newPasswordAgain.options.shake = false;
        }, 1000);
      }

      if (
        !this.updatePassword.fields.oldPassword.valid.value ||
        !this.updatePassword.fields.newPassword.valid.value ||
        !this.updatePassword.fields.newPasswordAgain.valid.value
      )
        return;

      this.$Progress.start();
      this.updatePassword.btnDisable = true;

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/profile/password/",
          {
            oldPassword: this.updatePassword.form.oldPassword,
            newPassword: this.updatePassword.form.newPassword,
          },
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish();
          this.updatePassword.btnDisable = false;

          if (response.data.result === "success") {
            this.cleanUpdatePasswordForm();

            Vue.swal({
              icon: "success",
              title: "Şifreniz başarıyla değişirildi.",
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Vue.swal.stopTimer);
                toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
              },
            });
          } else {
            switch (response.data.errCode) {
              case "COULD_NOT_CHANGE_PASSWORD":
                Vue.swal({
                  icon: "error",
                  title: "Şifre Değiştir",
                  html: "Şifre değiştirilemedi!",
                  confirmButtonText: "Tamam",
                });
                break;
              case "ACCOUNT_NOT_FOUND":
                Vue.swal({
                  icon: "error",
                  title: "Şifre Değiştir",
                  html: "Hesap bulunamadı!",
                  confirmButtonText: "Tamam",
                });
                break;
              case "OLD_PASSWORD_IS_INCORRECT":
                Vue.swal({
                  icon: "error",
                  title: "Şifre Değiştir",
                  html: "Eski şifre hatalı! Lütfen eski şifrenizi kontrol edin.",
                  confirmButtonText: "Tamam",
                });
                break;
            }
          }
        });
    },
  },
};
</script>